<template>
  <div class="terms">
      <loading :active.sync="isLoading"
               :is-full-page="fullPage"></loading>

      <div class="content-header row">
          <div class="content-header-left col-md-9 col-12 mb-2">
              <div class="row breadcrumbs-top">
                  <div class="col-12">

                      <div class="breadcrumb-wrapper col-12">
                          <ol class="breadcrumb">
                              <li class="breadcrumb-item"><a href="#">Dashboard</a>
                              </li>
                              <li class="breadcrumb-item"><a href="#">Get Quote</a>
                              </li>
                              <li class="breadcrumb-item"><a href="#">Quote Summary</a>
                              </li>
                              <li class="breadcrumb-item"><a href="#">Payment</a>
                              </li>
                              <li class="breadcrumb-item active">Underwriting
                              </li>
                          </ol>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="row mb-3">
          <div class="col-md-12">
              <hr/>
          </div>
      </div>

      <div class="container">
          <div class="row mt-2 mb- 5">
            <div class="col-md-12">

              <div class="text-center">
                  <h4>Kindly help us with the following details before you proceed</h4>
              </div>
              <div class=" p-5 mb-5  rounded">

                  <form-wizard ref="personalDetails" stepSize="sm" title="" subtitle="" finishButtonText="Confirm" action="#" v-on:submit.prevent="validateBeforeSubmit" @on-complete="validateBeforeSubmit" @onNextStep="nextStep" @onPreviousStep="previousStep">
                      <tab-content title="Personal details" :before-change="validatePersonalDetailsForm"
                                   icon="ti-user" :selected="true" >

                          <div class=" shadow-lg bg-white">

                              <div class="p-5">
                                  <div class="row">

                                      <div class="col-md-6">
                                          <label :class="{ 'text-danger': $v.coverDetails.fullName.$error }" >Full Names: <sup>*</sup>  </label> <br/>
                                          <input v-model.trim="$v.coverDetails.fullName.$model" type="text" class="form-control"  />
                                          <div class="text-danger" v-if="!$v.coverDetails.fullName.required && $v.coverDetails.fullName.$dirty">Full name is required</div>
                                      </div>
                                      <div class="col-md-6">
                                          <label :class="{ 'text-danger': $v.coverDetails.phoneNumber.$error }">Phone Number:<sup>*</sup> </label> <br/>
                                          <input v-model.trim="$v.coverDetails.phoneNumber.$model" type="text" class="form-control"  />

                                          <div class="text-danger" v-if="!$v.coverDetails.phoneNumber.required && $v.coverDetails.phoneNumber.$dirty">Phone number is required</div>
                                      </div>

                                  </div>
                                  <div class="row mt-3">
                                      <div class="col-md-6">
                                          <label :class="{ 'text-danger': $v.coverDetails.emailAddress.$error }">Email Address: <sup>*</sup> </label> <br/>
                                          <input type="text"  v-model.trim="$v.coverDetails.emailAddress.$model" class="form-control" />
                                          <div class="text-danger" v-if="!$v.coverDetails.emailAddress.required && $v.coverDetails.emailAddress.$dirty">Email address is required</div>
                                      </div>
                                      <div class="col-md-6">
                                          <label :class="{ 'text-danger': $v.coverDetails.identificationNumber.$error }">ID No. / Passport No. : <sup>*</sup> </label> <br/>
                                          <input type="text" v-model.trim="$v.coverDetails.identificationNumber.$model" class="form-control" v-model="coverDetails.identificationNumber" />

                                          <div class="text-danger" v-if="!$v.coverDetails.identificationNumber.required && $v.coverDetails.identificationNumber.$dirty">ID No. / Passport No is required</div>
                                      </div>
                                  </div>
                                  <div class="row mt-3">
                                      <div class="col-md-6">
                                          <label :class="{ 'text-danger': $v.coverDetails.postalCode.$error }" >Postal Code: <sup>*</sup> </label> <br/>
                                          <input v-model.trim="$v.coverDetails.postalCode.$model" type="text" class="form-control"  />
                                          <div class="text-danger" v-if="!$v.coverDetails.postalCode.required && $v.coverDetails.postalCode.$dirty">Postal code is required</div>
                                      </div>
                                      <div class="col-md-6">
                                          <label :class="{ 'text-danger': $v.coverDetails.poBoxNumber.$error }">P.O.Box Number: <sup>*</sup></label> <br/>
                                          <input v-model.trim="$v.coverDetails.poBoxNumber.$model" type="text" class="form-control"  />
                                          <div class="text-danger" v-if="!$v.coverDetails.poBoxNumber.required && $v.coverDetails.poBoxNumber.$dirty">P.O.Box Number is required</div>
                                      </div>

                                  </div>
                                  <div class="row mt-3">
                                      <div class="col-md-6">
                                          <label :class="{ 'text-danger': $v.coverDetails.kraPin.$error }">KRA Pin : <sup>*</sup> </label> <br/>
                                          <input v-model.trim="$v.coverDetails.kraPin.$model" type="text" class="form-control" />
                                          <div class="text-danger" v-if="!$v.coverDetails.kraPin.required && $v.coverDetails.kraPin.$dirty">KRA Pin is required</div>
                                      </div>

                                      <div class="col-md-6">
                                          <label :class="{ 'text-danger': $v.coverDetails.gender.$error }" >Gender: <sup>*</sup> </label> <br/>
                                          <select class="form-control"  v-model.trim="$v.coverDetails.gender.$model">
                                              <option value=""> -- Select -- </option>
                                              <option value="male"> Male</option>
                                              <option value="female"> Female</option>
                                          </select>
                                          <div class="text-danger" v-if="!$v.coverDetails.gender.required && $v.coverDetails.gender.$dirty">Gender is required</div>
                                      </div>

                                  </div>
                                  <div class="row mt-4">
                                      <div class="col-md-6">

                                          <h5 style="margin-bottom: 20px;">Preferred policy starting date: </h5>
                                          <label :class="{ 'text-danger': $v.coverDetails.preferredStartDate.$error }">Starting Date: </label> <br/>
                                          <date-picker v-model.trim="$v.coverDetails.preferredStartDate.$model" style="width: 100%;"  valueType="format"></date-picker>
                                          <div class="text-danger" v-if="!$v.coverDetails.preferredStartDate.required && $v.coverDetails.preferredStartDate.$dirty">Preferred policy starting date is required</div>
                                      </div>
                                  </div>
                              </div>


                          </div>
                      </tab-content>

                      <tab-content title="Vehicle details" :before-change="validateSchedule"
                                   icon="ti-user">

                          <div class="shadow-lg bg-white">
                              <div class="p-5">
                                  <div class="row p-3">
                                      <div class="col-md-6">
                                          <img style="max-width: 400px;" src="@/client/assets/images/vehicle_schedule.png">
                                      </div>
                                      <div class="col-md-6">

                                          <h5>Schedule For device installation and valuation</h5>

                                          <div class="mt-5 mb-4">
                                              <label>Date: </label> <br/>
                                              <date-picker style="width: 100%;" v-model="coverDetails.dateOfInstallation" valueType="format"></date-picker>
                                          </div>

                                          <div class=" mb-4">
                                              <label>Time: </label> <br/>
                                              <date-picker type="time" style="width: 100%;" v-model="coverDetails.timeOfInstallation" valueType="format"></date-picker>
                                          </div>

                                          <div class=" mb-4">
                                              <label class="control-label">Location</label> <br/>
                                              <gmap-autocomplete
                                                      :value="coverDetails.locationOfInstallation"

                                                      @place_changed="setPlace"
                                                      class="form-control"
                                              >
                                              </gmap-autocomplete>

                                              <small class="form-text text-muted">(Start typing to search location)</small>
                                          </div>
                                          <!--<hr/>
                                          <div class="row">

                                              <div class="col-md-12">
                                                  <gmap-map @click="clicked" :center="center" :zoom="9" style="width: 100%; height: 400px">
                                                      <gmap-marker :position="markers.position" :clickable="true" :draggable="false" @click="center=markers.position"></gmap-marker>
                                                  </gmap-map>
                                              </div>
                                          </div>-->
                                      </div>

                                  </div>
                              </div>
                          </div>

                      </tab-content>

                      <tab-content title="Cover Summary"
                                   icon="ti-user">
                          <div class="shadow-lg bg-white">
                              <div class="p-5">

                                  <div style="border: 1px solid #6F6F6F;border-radius: 6px;" class="p-3">

                                      <h5 style="margin-bottom: 20px;">Personal Details</h5>

                                      <table class="table">
                                          <tr>
                                              <td>Full Names</td>
                                              <td><strong>{{ coverDetails.fullName }}</strong></td>
                                              <td>Phone Number</td>
                                              <td><strong>{{ coverDetails.phoneNumber }}</strong></td>
                                          </tr>
                                          <tr>
                                              <td>Email Address</td>
                                              <td><strong>{{ coverDetails.emailAddress }}</strong></td>
                                              <td>ID No. / Passport No.</td>
                                              <td><strong>{{ coverDetails.identificationNumber  }}</strong></td>
                                          </tr>
                                          <tr>
                                              <td>Postal Code</td>
                                              <td><strong>{{ coverDetails.postalCode }}</strong></td>
                                              <td>KRA Pin</td>
                                              <td><strong>{{ coverDetails.kraPin }}</strong></td>
                                          </tr>
                                          <tr>
                                              <td>Gender</td>
                                              <td><strong>{{ coverDetails.gender }}</strong></td>
                                              <td>P.O.Box Number</td>
                                              <td><strong>{{ coverDetails.poBoxNumber }}</strong></td>
                                          </tr>
                                          <tr>
                                              <td>Starting Date</td>
                                              <td><strong>{{ coverDetails.preferredStartDate }}</strong></td>

                                          </tr>
                                      </table>
                                  </div>

                                  <div style="border: 1px solid #6F6F6F;border-radius: 6px;" class="mt-5 p-3">

                                      <h5 style="margin-bottom: 20px;">Vehicle Details</h5>

                                      <table class="table">
                                          <tr>
                                              <td>Date</td>
                                              <td><strong>{{ coverDetails.dateOfInstallation }}</strong></td>
                                              <td>Time</td>
                                              <td><strong>{{ coverDetails.timeOfInstallation }}</strong></td>
                                              <td>Location</td>
                                              <td><strong>{{ coverDetails.locationOfInstallation }}</strong></td>
                                          </tr>
                                      </table>

                                  </div>

                                  <div class="mt-5">
                                      <label> <input type="checkbox" v-model="coverDetails.termsAndCondition"> &nbsp;&nbsp;  <strong> By Confirming this you agree to our Terms and Conditions.</strong> </label>
                                  </div>

                              </div>
                          </div>
                      </tab-content>
                  </form-wizard>

              </div>
            </div>
          </div>
      </div>

      <modal name="success-checkout" height="550">
          <div class="p-5 text-center success-checkout">
              <img src="@/client/assets/images/success_check.png">

              <div class="mb-5">
                  <h4 class="text-success">Your payment was successful</h4>
              </div>


              <div class="mb-5">
                  If you’re a <strong>new User</strong> Kindly check your <strong>email inbox <br/>
                  or spam folder </strong> for further instructions from AICARE.
              </div>

              <div style="border: 1px solid #6F6F6F;border-radius: 6px;" class="p-3">

                  <div class="mt-4 mb-4">
                      If you already have an account
                  </div>

                  <RouterLink style="width: 100%;font-weight: bolder;" to="/login" class="btn  btn-secondary btn-block quote-btn" > Login </RouterLink>
              </div>
          </div>
      </modal>


  </div>
</template>



<script>

    import {FormWizard, TabContent} from 'vue-form-wizard'
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'
    import { required} from 'vuelidate/lib/validators'

    import carBrands from "./car_brands";

    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import { mapActions } from "vuex";
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name: 'home',
    data () {
      return {
          place_desc:"",
          isLoading: false,
          fullPage: true,
          coverDetails:{
              quoteId:"",
              preferredStartDate:"",
              dateOfInstallation:"",
              timeOfInstallation:"",
              locationOfInstallation:"",
              kraPin:"",
              postalCode:"",
              poBoxNumber:"",
              vehicleReg:"",
              fullName:"",
              phoneNumber:"",
              idType:"",
              identificationNumber:"",
              emailAddress:"",
              gender:"",
              termsAndCondition:""
          },
          car_brands:carBrands,
          markers: {
              position: { lat: -1.292066, lng: 36.821946  }
          },
          center: { lat: -1.292066, lng: 36.821946 },
      };
    },
      validations: {
          coverDetails:{
              preferredStartDate:{required},
              kraPin:{required},
              postalCode:{required},
              poBoxNumber:{required},
              fullName:{required},
              phoneNumber:{required},
              emailAddress:{required},
              gender:{required},
              identificationNumber:{required},
          }
      },
      components: {
          FormWizard,
          DatePicker,
          TabContent,
          Loading
      },

      mounted(){
            this.getQuoteData();
      },
    methods:{
        ...mapActions(["getQuoteDetails","updatePersonalQuoteInformation","updateValuation","finalizeQuoteDetails"]),

        setPlace(place) {

            console.log(place);

            this.coverDetails.locationOfInstallation = place.name;


            this.markers.position.lat = place.geometry.location.lat();
            this.markers.position.lng = place.geometry.location.lng();

            this.center = this.markers.position;

        },

        clicked(e) {


            this.markers.position.lat = e.latLng.lat();
            this.markers.position.lng = e.latLng.lng();

            this.center = this.markers.position;
        },

        validatePersonalDetailsForm(){

            let self = this;

            return new Promise((resolve, reject) => {

                self.$v.$touch();
                if (!self.$v.$invalid) {
                    resolve(true);
                    self.updatePersonalDetails();

                }

            });


        },

        getQuoteData(){
            let self = this;
            this.isLoading = true;

            this.getQuoteDetails({id: this.$route.params.quoteId}).then((quote) => {
                self.isLoading = false;

                this.coverDetails.fullName = quote.fullName;
                this.coverDetails.phoneNumber = quote.phoneNumber;
                this.coverDetails.emailAddress = quote.emailAddress;
                this.coverDetails.identificationNumber = quote.identificationNumber;
                this.coverDetails.postalCode = quote.postalCode;
                this.coverDetails.poBoxNumber = quote.poBoxNumber;
                this.coverDetails.kraPin = quote.kraPin;
                this.coverDetails.gender = quote.gender;
                this.coverDetails.preferredStartDate = quote.preferredStartDate;
                this.coverDetails.quoteId = quote._id;


            }).catch(error => {
                self.isLoading = false;
                console.log(error);
            })
        },

        updatePersonalDetails:function() {
            let self = this;
            return new Promise((resolve, reject) => {

                self.isLoading = true;

                let quoteData = {
                    fullName:this.coverDetails.fullName,
                    phoneNumber:this.coverDetails.phoneNumber,
                    emailAddress:this.coverDetails.emailAddress,
                    config:"switch",
                    identificationNumber: this.coverDetails.identificationNumber,
                    postalCode:this.coverDetails.postalCode,
                    poBoxNumber: this.coverDetails.poBoxNumber,
                    kraPin:this.coverDetails.kraPin,
                    gender:this.coverDetails.gender,
                    preferredStartDate:this.coverDetails.preferredStartDate
                };

                self.updatePersonalQuoteInformation({quoteId: this.coverDetails.quoteId, quoteData: quoteData}).then((quote) => {

                    console.log(quote);
                    self.isLoading = false;

                    resolve(true);



                }).catch(error => {
                    console.log(error);
                    self.isLoading = false;

                    return false;


                })
            })
        },

        validateSchedule:function() {
            let self = this;
            return new Promise((resolve, reject) => {

                self.isLoading = true;

                let quoteData = {
                    date:this.coverDetails.dateOfInstallation,
                    time:this.coverDetails.timeOfInstallation,
                    location: this.markers.position.lat+", "+this.markers.position.lng
                };

                self.updateValuation({quoteId: this.coverDetails.quoteId, quoteData: quoteData}).then((quote) => {

                    console.log(quote);
                    self.isLoading = false;

                    resolve(true);



                }).catch(error => {
                    console.log(error);
                    self.isLoading = false;

                    return false;


                })
            })
        },

        updateUserInformation(){

            let self = this;

            this.isLoading = true;

            let quoteData = {
                fullName:this.coverDetails.fullName,
                phoneNumber:this.coverDetails.phoneNumber,
                emailAddress:this.coverDetails.emailAddress,
                config:"switch",
            };


            this.updatePersonalQuoteInformation({quoteId: this.coverDetails.quoteId, quoteData: quoteData}).then((response) => {
                self.isLoading = false;

                console.log(response)
            }).catch(error => {
                self.isLoading = false;
                console.log(error);
            })
        },

        /*updateQuoteData(){

            console.log("Before change called");

            let self = this;

            this.isLoading = true;

            let quoteData = {
                fullName:this.coverDetails.fullName,
                phoneNumber:this.coverDetails.phoneNumber,
                emailAddress:this.coverDetails.emailAddress,
                config:"switch",
                identificationNumber: this.coverDetails.identificationNumber,
                postalCode:this.coverDetails.postalCode,
                kraPin:this.coverDetails.kraPin,
                gender:this.coverDetails.gender,
                preferredStartDate:this.coverDetails.preferredStartDate
            };

            this.updatePersonalQuoteInformation({quoteId: this.coverDetails.quoteId, quoteData: quoteData}).then((quote) => {

                console.log(quote);
                self.isLoading = false;

                self.$refs.personalDetails.nextTab()



            }).catch(error => {
                console.log(error);
                self.isLoading = false;

                return false;


            })


        },*/

        nextStep(e){
            console.log("Next step");
            console.log(e);
        },
        previousStep(e){
            console.log("Previous step");
            console.log(e);
        },
        validateBeforeSubmit(){

            let self = this;

            return new Promise((resolve, reject) => {

                self.isLoading = true;

                self.finalizeQuoteDetails({quoteId: self.coverDetails.quoteId}).then((response) => {
                    self.isLoading = false;

                    resolve(true);

                    //self.$modal.show('success-checkout');



                }).catch(error => {
                    self.isLoading = false;
                    console.log(error);
                })
            });


        }
    }
  }
</script>

<style >

    .vue-form-wizard .wizard-icon-circle .wizard-icon-container{
        background-color: transparent !important;
    }
    .vue-form-wizard.sm .wizard-nav-pills>li.active>a .stepTitle{
        color:#13223a !important;
    }


    .vue-form-wizard.sm .wizard-nav-pills>li.active>a .wizard-icon{
        height: 50px !important;
        width: 50px !important;
        margin-top: -13px;
        background-image: url("../assets/images/progress_car.png") !important;
        background-repeat: no-repeat;

    }




    .vue-form-wizard .wizard-tab-content {
        padding-top: 50px;
    }
    .stepTitle{
        margin-top: -50px;
        color:#6F6F6F;
        font-weight: 600;
    }
    .vue-form-wizard.sm .wizard-icon-circle{
        height: 10px;
        width: 10px;
        background-color: #C1C1C1;
        border: none;
    }
    .vue-form-wizard.sm .wizard-navigation .wizard-progress-with-circle{
        top:9px;
        left:16.5%;
        width: 66.5% !important;
        background-color: #C1C1C1 !important;
        height: 2px !important;
    }
    table tr th{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    fieldset.custom-border {
        border: 1px groove #ddd !important;
        padding: 0 1.4em 1.4em 1.4em !important;
        margin: 0 0 1.5em 0 !important;
        -webkit-box-shadow: 0px 0px 0px 0px #000;
        box-shadow: 0px 0px 0px 0px #000;
    }

    legend.custom-border {
        width: auto;
        padding: 0 10px;
        border-bottom: none;
    }
    .vue-step-wizard{
        width: 100% !important;
    }
    .tabStatus{
        background-color: #002563 !important;
    }
    .tabLabel{
        font-weight: bolder;
    }

    label{
        font-size: 14px;
    }

    .vue-form-wizard .wizard-navigation .wizard-progress-with-circle .wizard-progress-bar{
        width: 0% !important;
    }
    .vue-form-wizard .navbar .navbar-nav>li>a.wizard-btn, .vue-form-wizard .wizard-btn{
        background-color: #d7ff1d !important;
        color:#000 !important;
        border: none;
        margin-top: 20px;

    }

    table td{
        padding: 10px;
    }
</style>
